import regex from '@util/regex';
import {
    isValidJavascript,
    isValidCss,
} from './utils';
import store from '../../store/index';

/**
 * Checks if an ad loading override is valid.
 *
 * @param {Object} adLoadingOverride - The ad loading override object to validate.
 * @returns {boolean} Returns true if the ad loading override is valid, false otherwise.
 */
export function isAdLoadingOverrideValid(adLoadingOverride) {
    const hasInvalidDevice = adLoadingOverride.devices.some(
        (d) =>
            d.delayTime &&
            (!/^\d+$/.test(d.delayTime) || (parseInt(d.delayTime, 10) < 100 || parseInt(d.delayTime, 10) > 20000))
    );

    return !(
        adLoadingOverride.option === 'delayAdLoad' &&
        hasInvalidDevice
    );
}

/**
 * Checks if an array of passbacks is valid.
 *
 * @param {Array} passbacks - The array of passbacks to validate.
 * @returns {boolean} Returns true if all passbacks are valid, false otherwise.
 */
export function arePassbacksValid(passbacks) {
    const hasInvalidPassbackCallbacks = passbacks.some(passback => !isValidJavascript(passback.callback));
    const hasInvalidPassbackKeys = passbacks.some(passback => passback.key !== '' && !regex.alpha_num_dash.test(passback.key));

    return !(hasInvalidPassbackCallbacks || hasInvalidPassbackKeys);
}

/**
 * Checks if the custom CSS is valid.
 *
 * @param {string} customCss - The custom CSS to validate.
 * @returns {boolean} Returns true if the classes and templates are valid, false otherwise.
 */
export function isCustomCssValid(customCss) {
    return isValidCss(customCss);
}

/**
 * Check if the custom JS is valid.
 *
 * @param {string} customJs - The custom JS to validate.
 * @returns {boolean} Returns true if the JS is valid, false otherwise.
 */
export function isCustomJsValid(customJs) {
    return isValidJavascript(customJs);
}

/**
 * Check if the admin Custom JS is valid.
 *
 * @param {string} adminCustomJs - The admin Custom JS to validate.
 * @returns {boolean} Returns true if the JS is valid, false otherwise.
 */
export function isAdminCustomJsValid(adminCustomJs) {
    return isValidJavascript(adminCustomJs);
}

/**
 * Checks for Ad loading override warnings.
 *
 * @param {Object} adLoadingOverride - The ad loading override object to validate.
 * @returns {boolean} - Returns true if the ad loading override has warnings, false otherwise.
 */
export function hasAdLoadingOverrideWarnings(adLoadingOverride) {
    const hasInvalidDevice =
        adLoadingOverride.devices.find(d => d.delayTime === '') !== undefined;
    return adLoadingOverride.option === 'delayAdLoad' && hasInvalidDevice;
}

/**
 * Checks for Ad loading override errors, sets warnings and errors in the store.
 *
 * @param {Object} adLoadingOverride - The ad loading override object to validate.
 */
export function validateAdLoadingOverride(adLoadingOverride) {
    const hasWarnings = hasAdLoadingOverrideWarnings(adLoadingOverride);
    const hasErrors =
        !isAdLoadingOverrideValid(adLoadingOverride);

    if (hasWarnings) {
        store.dispatch('sites/addWarningSubtab', 'ad-loading-override');
    } else {
        store.dispatch('sites/removeWarningSubtab', 'ad-loading-override');
    }

    if (hasErrors) {
        store.dispatch('sites/addErrorSubtab', 'ad-loading-override');
    } else {
        store.dispatch('sites/removeErrorSubtab', 'ad-loading-override');
    }
}
