import {
    login,
    logout,
    getUserInformation,
    requestResetPassword,
    checkToken,
} from '@api/authentication';
import { getAccounts } from '@api/accounts';
import router from '@router/router';
import storage from '@config/localStorage';
import { getInstance } from '../../auth'

const state = {
    userInformation: {
        id: '',
        name: '',
        role: '',
        accounts: '',
        accountsBySite: [],
        selectedAccount: {},
    },
};

const mutations = {
    SET_USER_INFORMATION(state, userInformation) {
        state.userInformation = userInformation;
    },
    SET_USER_SELECTED_ACCOUNT(state, selectedAccount) {
        state.userInformation.selectedAccount = selectedAccount;
    },
    RESET_USER_INFORMATION(state) {
        state.userInformation = {
            id: '',
            name: '',
            role: '',
            accounts: '',
            accountsBySite: [],
            selectedAccount: {},
        };
    },
    SET_USER_ACCOUNTS_BY_SITE(state, accountsBySite) {
        state.userInformation.accountsBySite = accountsBySite;
    },
};

const actions = {
    async login({ dispatch }, credentials) {
        try {
            const { token, refreshToken, userInformation } = await login(credentials);
            storage.set('token', token);
            storage.set('refresh-token', refreshToken);
            dispatch('setUserInformation', userInformation);
            dispatch('resetAllQueryParams');
            storage.set('legacyLogin', true);
            router.push({ name: 'mainView' });
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    async getUserInformation({ dispatch }) {
        try {
            const userInformation = await getUserInformation();
            dispatch('setUserInformation', userInformation);
        } catch (e) {
            console.log('error ', e);
        }
    },
    setUserInformation({ commit }, userInformation) {
        let selectedAccount = JSON.parse(localStorage.getItem(userInformation.id));

        if (
            !selectedAccount ||
      (userInformation.accounts.length > 0 &&
        !userInformation.accounts.some(
            a => a.account_id === selectedAccount.value
        ))
        ) {
            let firstAccount =
        userInformation.accounts.length > 0 ? userInformation.accounts[0] : {};
            selectedAccount = {
                value: firstAccount.account_id,
                text: firstAccount.name,
            };
        } else if (userInformation.accounts.length === 0) {
            selectedAccount = {};
        }
        userInformation.selectedAccount = selectedAccount;
        userInformation.accountsBySite = [];
        commit('SET_USER_INFORMATION', userInformation);
        storage.set(
            userInformation.id,
            JSON.stringify(userInformation.selectedAccount)
        );
    },
    setUserSelectedAccount({ state, commit }, selectedAccount) {
        commit('SET_USER_SELECTED_ACCOUNT', selectedAccount);
        storage.set(state.userInformation.id, JSON.stringify(selectedAccount));
    },
    async logout({ commit }, clearServerSession = false) {
        if (clearServerSession) await logout();
        storage.remove('token');
        storage.remove('refresh-token');
        const usesLegacyLogin = storage.get('legacyLogin') === 'true';
        if (!usesLegacyLogin) {
            const auth0 = getInstance();
            await auth0.logout({
                logoutParams: {
                    returnTo: window.location.origin
                }
            });
        } else {
            router.push({ name: 'legacy-login' });
        }
        commit('RESET_USER_INFORMATION');
    },
    async requestResetPassword(_, email) {
        try {
            await requestResetPassword({ email });
        } catch (e) {
            console.log('error ', e);
            throw e.error;
        }
    },
    async getUserAccountsBySite({ state, commit }, siteDomain) {
        try {
            const { accounts } = await getAccounts({
                userId: state.userInformation.id,
                siteDomain,
            });
            commit('SET_USER_ACCOUNTS_BY_SITE', accounts);
        } catch (e) {
            console.log('error ', e);
        }
    },
    async verifyAuth0Token({ dispatch }, token) {
        try {
            const response = await checkToken(token);
            if (response.data) {
                dispatch('setUserInformation', response.data);
                storage.remove('legacyLogin');
                storage.set('token', token);
                return {
                    success: true,
                };
            }
            storage.remove('token');
            return {
                error: 'Invalid token. Redirecting...',
            }
        } catch(e) {
            // this code is returned when a user is found with a matching email but does not have bid's metadata
            // this means the user was added by a different app but has an identity in our DB as well
            // the bid metadata gets updated on the backend but this requires a new token
            if (e.code === 'USER_UPDATED') {
                const instance = getInstance();
                await instance.logout();
                await instance.loginWithRedirect();
                return;
            }
            storage.remove('token');
            return {
                error: `${e.error ? e.error : 'An error has occurred.'} Redirecting...`,
            }
        }
    }
};

const getters = {
    userInformation: ({ userInformation }) => userInformation,
    isUserAuthenticated: ({ userInformation }) => userInformation.id !== '',
};

export default {
    state,
    mutations,
    actions,
    getters,
};
