import axios from 'axios';
import router from '../../main';
import store from '../../store/index';
import storage from '../localStorage';
import { refreshToken } from '../../api/authentication';
import { getInstance } from '../../auth';

const processQueue = (error, token = null) => {
    store.getters.failedReqQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    store.dispatch('resetFailedReqQueue');
};

export const authorizationInterceptorRequestSuccess = req => {
    const accessToken = storage.get('token');

    req.headers.Authorization = accessToken;

    return req;
};

export const accountInterceptorRequestSuccess = req => {
    const { selectedAccount } = store.getters.userInformation;

    if (selectedAccount) req.headers['X-Account-Id'] = selectedAccount.value;

    return req;
};

export const authorizationInterceptorResponseError = async (err) => {
    if (err.response?.status === 502 || err.response?.status === 504) {
        return Promise.reject('HTL BID had an internal issue during the log in process, which should be resolved shortly. Please wait 60 seconds and log in again. Contact the HTL team if the issue persists.');
    }
    if (
        err.response.status === 401 &&
        err.response.data.code === 'REFRESH_TOKEN_INVALID'
    ) {
        store.dispatch('logout');
        return Promise.reject(
            'Session expired. Please log in again to continue using the app.'
        );
    }
    if (
        err.response.status === 401 &&
        err.response.data.code === 'CREDENTIALS_INVALID'
    ) {
        return Promise.reject(err.response.data.error);
    }
    if (
        err.response.status === 401 &&
        err.response.config &&
        !err.response.config.__isRetryRequest
    ) {
        const usesAuth0 = storage.get('usesAuth0') === 'true';
        if (usesAuth0) {
            const auth0Instance = getInstance();
            try {
                const auth0Token = await auth0Instance.getTokenSilently();
                if (auth0Token) {
                    storage.set('token', auth0Token);
                    err.response.config.headers.Authorization = auth0Token;
                    err.response.config.__isRetryRequest = true;
                    return axios(err.response.config).then(res => {
                        return res.data;
                    });
                } else {
                    storage.remove('token');
                    return router.push({ name: 'sso' });
                }
            } catch (e) {
                storage.remove('token');
                return router.push({ name: 'sso' });
            }
        }
        if (!usesAuth0 && !storage.get('refresh-token')) return Promise.reject(401);
        if (!usesAuth0 && store.getters.isRefreshingToken) {
            return new Promise((resolve, reject) => {
                // skip storing failed requests for refresh token reqs
                const isRequestingRefreshToken =
          err.response.config.method.toLowerCase() === 'post' &&
          /^\/api\/v1\/login\/refresh-token\/\d+/.test(err.response.config.url);

                if (!isRequestingRefreshToken)
                    store.dispatch('addFailedRequestToQueue', { resolve, reject });
            })
                .then(token => {
                    err.response.config.headers.Authorization = token;
                    return axios(err.response.config).then(res => {
                        return res.data;
                    });
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        } else if (!usesAuth0) {
            store.dispatch('setIsRefreshingToken', true);

            return new Promise((resolve, reject) => {
                refreshToken()
                    .then(
                        success => {
                            storage.set('token', success.token);
                            storage.set('refresh-token', success.refreshToken);
                            err.response.config.__isRetryRequest = true;
                            err.response.config.headers.Authorization = success.token;
                            processQueue(null, success.token);
                            axios(err.response.config).then(
                                res => {
                                    resolve(res.data);
                                },
                                error => {
                                    reject(error.response.data);
                                }
                            );
                        },
                        error => {
                            processQueue(error, null);
                            store.dispatch('logout');
                            reject(error);
                        }
                    )
                    .catch(err => {
                        processQueue(err, null);
                        store.dispatch('logout');
                        reject(err);
                    })
                    .finally(() => {
                        store.dispatch('setIsRefreshingToken', false);
                    });
            });
        }
    }

    if (err.response.status === 403) {
        router
            .push({ name: 'error', params: { type: 'forbidden' } })
            .catch(err => {}); // eslint-disable-line no-unused-vars
    }

    return Promise.reject(err.response.data);
};

export const requestLoadingInterceptor = req => {
    //skip loading for automatic reloading
    const isFetchingRevisions =
    req.method.toLowerCase() === 'get' &&
    /^\/api\/v1\/sites\/\d+\/versions/.test(req.url);
    const isFetchingLineitems =
    req.method.toLowerCase() === 'get' &&
    /^\/api\/v1\/line-items/.test(req.url);
    const isFetchingTargeting =
    req.method.toLowerCase() === 'get' && /^\/api\/v1\/targeting/.test(req.url);
    const isFetchingJobOverview =
    req.method.toLowerCase() === 'get' &&
    /^\/api\/v1\/line-items\/\d+/.test(req.url);
    const isFetchingSitesForGamServices =
    router.currentRoute.path.includes('/gam-services') &&
    req.method.toLowerCase() === 'get' &&
    /^\/api\/v1\/sites/.test(req.url);
    const isFetchingIdUploads =
    router.currentRoute.path.includes('/prebid-id-uploads') &&
    req.method.toLowerCase() === 'get' &&
    /^\/api\/v1\/id-uploads$/.test(req.url);

    const shouldSkipStartingLoader =
    isFetchingRevisions ||
    isFetchingLineitems ||
    isFetchingTargeting ||
    isFetchingJobOverview ||
    isFetchingSitesForGamServices ||
    isFetchingIdUploads;

    if (shouldSkipStartingLoader) {
        return req;
    }

    store.dispatch('showLoadingSpinner', true, { root: true });
    return req;
};

export const responseSuccessLoadingInterceptor = res => {
    const { method, url } = res.config;

    //prevent stopping loader for revisions
    const isFetchingRevisions =
    method.toLowerCase() === 'get' &&
    /^\/api\/v1\/sites\/\d+\/versions/.test(url);

    if (!isFetchingRevisions) {
        store.dispatch('showLoadingSpinner', false, { root: true });
    }

    return res;
};

export const responseErrorLoadingInterceptor = err => {
    store.dispatch('showLoadingSpinner', false, { root: true });
    return Promise.reject(err);
};
