/* eslint-disable */

import store from '../store/index';
import storage from '../config/localStorage';
import router from './router';
import { waitForAuth0Instance } from '../auth';

export const authGuard = async (to, from, next) => {
    const { authorize } = to.meta;
    let userRole = store.getters.userInformation.role;
    let { isUserAuthenticated } = store.getters;

    const usesAuth0 = storage.get('usesAuth0') === 'true';
    const loginRoutes = ['login', 'sso', 'callback'];

    /* if the route is RESTRICTED to only authenticated users
     we need to check if the user is authenticated */
    if (authorize) {
    /* if the user role is unknown in the local vuex store -- and the user was not logged out before
       we need to call backend to check if the user is authenticated
       this is the case when the user reloads the page -> the vuex store gets cleared */
        if (usesAuth0 && !userRole) {
            const auth0 = await waitForAuth0Instance();
            try {
                const token = await auth0.getTokenSilently();
                storage.set('token', token);
                await store.dispatch('getUserInformation');
                isUserAuthenticated = store.getters.isUserAuthenticated;
                userRole = store.getters.userInformation.role;
            } catch(e) {
                storage.remove('token');
                storage.remove('refresh-token');
                return next({ name: 'sso' });
            }
        } else if (!usesAuth0 && !userRole && storage.get('refresh-token')) {
            // unkwnown
            await store.dispatch('getUserInformation');
            // wait for backend call and then read again from our vuex store
            isUserAuthenticated = store.getters.isUserAuthenticated;
            userRole = store.getters.userInformation.role;
        }

        /* now as we covered the potential reload case, we can check if user is authenticated
       if user is not authenticated -> redirect him to the login page */
        if (!loginRoutes.includes(to.name) && !isUserAuthenticated) {
            if (usesAuth0) {
                return next({ name: 'sso' });
            } else {
                return next({ name: 'login' });
            }
        }

        /* ok - user is authenticated but if a route is restricted only to authorized users
       we need to check for the user's role */
        if (authorize.length && !authorize.includes(userRole)) {
            // user's role is not authorised -> redirect him to home page
            return next({ path: '/' });
        }
    }

    /* ELSE - NOT RESTRICTED ROUTES - check only for login route */

    /* if user is logged in and try to access the login route
     we need to call backend to check if the user is authenticated
     since the local vuex store is currently empty  */
    if (loginRoutes.includes(to.name) && storage.get('token')) {
        try {
            await store.dispatch('getUserInformation');
            isUserAuthenticated = store.getters.isUserAuthenticated;
            if (isUserAuthenticated) {
                // ok - user is authenticated -> redirect him to home page
                return next({ path: '/' });
            }
        } catch (e) {
            alert('error', e);
            // user is not authenticated -> allow him to go to the login page
            return next();
        }
    }

    // ALL CHECKS PASSED - allow user to go to the requested route
    return next();
};

export const notFoundGuard = (to, from, next) => {
    if (to.matched.length === 0) {
        router.push({ name: 'error', params: { type: 'default' } });
        store.dispatch('setNotFound', true);
        next();
    } else {
        store.dispatch('setNotFound', false);
        next();
    }
};
