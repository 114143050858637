import {
    fetchSites,
    createSite,
    getSiteById,
    updateSite,
    copySite,
    fetchSitesByAccountId,
} from '@api/sites';
import version from './version';
import overview from './overview';

const state = {
    sites: [],
    site: {},
    htmlErrors: [],
};

const mutations = {
    SET_SITES(state, sites) {
        state.sites = sites;
    },
    SET_SITE(state, site) {
        state.site = site;
    },
    SET_HTML_ERRORS(state, payload) {
        const { uuid, errors } = payload;
        if (errors.length > 0) {
            state.htmlErrors = [
                ...state.htmlErrors,
                ...errors.map(error => ({ ...error, uuid })),
            ];
        } else {
            state.htmlErrors = state.htmlErrors.filter(error => error.uuid !== uuid);
        }
    },
};

const actions = {
    async getSites({ commit, rootGetters }, params) {
        try {
            const { data, metadata } = await fetchSites({
                ...rootGetters.queryParams,
                ...params,
            });
            commit('SET_SITES', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async getSitesByAccountId({ commit, rootGetters }, accountId, params) {
        try {
            const { data, metadata } = await fetchSitesByAccountId(accountId, {
                ...rootGetters.queryParams,
                ...params,
            });
            commit('SET_SITES', data);
            commit('SET_PAGINATION', metadata.pagination, { root: true });
        } catch (e) {
            console.log(e);
        }
    },

    async createSite({ commit }, site) {
        const params = {
            domain: site.domain,
        };

        try {
            const site = await createSite(params);
            commit('SET_SITE', site.site);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },

    async getSiteById({ commit, rootGetters }, siteId) {
        try {
            const { site } = await getSiteById(siteId, rootGetters.queryParams);
            commit('SET_SITE', site);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },

    async updateSite({ dispatch }, site) {
        try {
            await updateSite(site.site_id, site);
            dispatch(
                'setAlertMessage',
                { message: 'Your changes have been saved.' },
                { root: true }
            );
        } catch (e) {
            console.log('error ', e);
            dispatch(
                'setAlertMessage',
                { message: e.error, type: 'error' },
                { root: true }
            );
        }
    },

    async copySite({ commit }, { siteId, domain }) {
        try {
            const body = { domain };
            const { site } = await copySite(siteId, body);
            commit('SET_SITE', site);
        } catch (e) {
            console.log('error ', e);
            throw e;
        }
    },
    setHtmlErrors({ commit }, payload) {
        commit('SET_HTML_ERRORS', payload);
    },
};

const getters = {
    sites: ({ sites }) => sites,
    site: ({ site }) => site,
    htmlErrors: ({ htmlErrors }) => htmlErrors,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
    modules: { version, overview },
};
