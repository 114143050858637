/* eslint-disable */

import Vue from 'vue';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import storage from '@config/localStorage';

let instance;
let initAuth0Promise;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
    redirectUri = `${window.location.origin}/callback`,
    ...options
}) => {
    if (instance) return instance;

    // Create an initialization promise that will resolve once Auth0 is fully mounted
    initAuth0Promise = new Promise(async (resolve, reject) => {
        try {
            instance = new Vue({
                data() {
                    return {
                        loading: true,
                        isAuthenticated: false,
                        user: {},
                        auth0Client: null,
                        popupOpen: false,
                        error: null
                    };
                },
                methods: {
                    /** Authenticates the user using a popup window */
                    async loginWithPopup(options, config) {
                        this.popupOpen = true;

                        try {
                            await this.auth0Client.loginWithPopup(options, config);
                            this.user = await this.auth0Client.getUser();
                            this.isAuthenticated = true;
                            this.error = null;
                        } catch (e) {
                            this.error = e;
                            console.error(e);
                        } finally {
                            this.popupOpen = false;
                        }
                    },
                    /** Handles the callback when logging in using a redirect */
                    async handleRedirectCallback() {
                        this.loading = true;
                        try {
                            await this.auth0Client.handleRedirectCallback();
                            this.user = await this.auth0Client.getUser();
                            this.isAuthenticated = true;
                            this.error = null;
                            storage.set('legacyLogin', false);
                        } catch (e) {
                            this.error = e;
                        } finally {
                            this.loading = false;
                        }
                    },
                    /** Authenticates the user using the redirect method */
                    loginWithRedirect(o) {
                        return this.auth0Client.loginWithRedirect(o);
                    },
                    /** Returns all the claims present in the ID token */
                    getIdTokenClaims(o) {
                        return this.auth0Client.getIdTokenClaims(o);
                    },
                    /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
                    getTokenSilently(o) {
                        return this.auth0Client.getTokenSilently(o);
                    },
                    /** Gets the access token using a popup window */
                    getTokenWithPopup(o) {
                        return this.auth0Client.getTokenWithPopup(o);
                    },
                    /** Logs the user out and removes their session on the authorization server */
                    logout(o) {
                        return this.auth0Client.logout(o);
                    }
                },
                /** Use this lifecycle method to instantiate the SDK client */
                async created() {
                    this.auth0Client = await createAuth0Client({
                        ...options,
                        authorizationParams: {
                            redirect_uri: redirectUri,
                            ...options.authorizationParams,
                            ...options
                        }
                    });

                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                    this.user = await this.auth0Client.getUser();
                    this.loading = false;
                    resolve(instance);
                }
            });
        } catch (error) {
            reject(error);
        }
    });

    return instance;
};

export const waitForAuth0Instance = () => initAuth0Promise;

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth0(options);
    }
};
