import vh from '@util/versionHelpers';
import {
    isValidCssSelector,
    isAditudeOverrideValid,
    isDomainValid,
} from '@util/validation/utils';
import {
} from '@util/validation/identity';
import {
} from '@util/validation/monitoring';
import {
    isBidderThrottlingValid,
    isPrebidBidAdjustmentValid,
    isPrebidCurrencyValid,
    isPrebidFirstLookValid,
    isPrebidFloorsValid,
    isPrebidServerValid,
    isPrebidValid,
    isTrafficShapingBiddersValid,
    isTrafficShapingConditionsValid,
    isTrafficShapingValid,
    hasPrebidFloorsDuplicateCols,
    hasPrebidFloorsDuplicateRows,
    isUserSyncValid,
    isBucketsAllValid
} from '@util/validation/prebid';
import {
    isSchainValid,
    isSchainsInvalid,
    areAuthorizedDomainsValid
} from '@util/validation/accountInformation';
import {
    isClassesAndTemplatesValid,
    isDevicesAndViewportsValid,
    isNativeStylesInvalid,
    isNativeStyleInvalid
} from '@util/validation/adConfiguration';
import {
    isAdLoadingOverrideValid,
    arePassbacksValid,
    isCustomCssValid,
    isCustomJsValid
} from '@util/validation/techConfig';
import {
    isConsentManagerValid
} from '@util/validation/consentManagement';
import {
    isAmazonValid
} from '@util/validation/amazon';
import {
    isAbTestingValid
} from '@util/validation/abTesting';

let validators = {};

/**
 * Add a module validator function to the 'validators' object.
 *
 * @param {string} key - The key under which the validator function will be stored.
 * @param {function} func - The validator function to be added.
 */
export function addModuleValidator(key, func) {
    if (typeof validators === 'undefined') {
        validators = {};
    }
    validators[key] = func;
}

/**
 * Main module validation function.
 *
 * @param {string} moduleKey - The key of the module to validate.
 * @param {Object} config - The main configuration object.
 * @returns {boolean} Returns true if the module is valid, false otherwise.
 */
export function isModuleValid(moduleKey, config) {
    switch (moduleKey) {

    case 'hasCustomJs':
        return isCustomJsValid(config.customJs);

    case 'hasCss':
        return isCustomCssValid(config.css);

    case 'hasPassbacks':
        return arePassbacksValid(config.passbacks);

    case 'hasAmazonSchain':
        return isSchainValid(config);

    case 'hasPrebidSchain':
        return isSchainValid(config);

    case 'hasAmazon':
        return isAmazonValid(config.amazon);

    case 'hasPrebid':
        return isPrebidValid(config) && isPrebidBidAdjustmentValid(config) && isBucketsAllValid(config);

    case 'hasPrebidCurrency':
        return isPrebidCurrencyValid(config);

    case 'hasPrebidFloors':
        return isPrebidFloorsValid(config.prebidFloors);

    case 'hasPrebidServer':
        return isPrebidServerValid(config);

    case 'hasPrebidFirstLook':
        return isPrebidFirstLookValid(config);

    case 'hasBidderThrottling':
        return isBidderThrottlingValid(config.bidderThrottling);

    case 'hasTrafficShaping':
        return isTrafficShapingValid(config);

    case 'hasConsentManagerVersion2':
        return isConsentManagerValid(config.consentManagerVersion2);

    case 'hasLayouts': {
        const mappedLayouts = vh.getMappedLayouts(
            config.layouts.layouts || [vh.defaultUniversalLayout]
        );
        return (
            isClassesAndTemplatesValid(mappedLayouts, config) &&
            isDevicesAndViewportsValid(config.devices)
        );
    }

    case 'hasNativeStyles':
        return !isNativeStylesInvalid(config);

    case 'isTestMode':
        return isAbTestingValid(config.abTesting);

    case 'hasAuthorizedDomains':
        return areAuthorizedDomainsValid(config.authorizedDomains);

    case 'hasAdLoadingOverride':
        return isAdLoadingOverrideValid(config.adLoadingOverride);

    default:
        return true;
    }
}

export default {
    isModuleValid,
    isCustomJsValid,
    isCustomCssValid,
    isAdLoadingOverrideValid,
    arePassbacksValid,
    isAmazonValid,
    isPrebidValid,
    isPrebidCurrencyValid,
    isPrebidFloorsValid,
    isPrebidServerValid,
    isPrebidBidAdjustmentValid,
    hasPrebidFloorsDuplicateRows,
    hasPrebidFloorsDuplicateCols,
    isPrebidFirstLookValid,
    isBidderThrottlingValid,
    isTrafficShapingValid,
    isTrafficShapingBiddersValid,
    isTrafficShapingConditionsValid,
    isConsentManagerValid,
    isAbTestingValid,
    isSchainsInvalid,
    isValidCssSelector,
    isNativeStylesInvalid,
    isNativeStyleInvalid,
    isAditudeOverrideValid,
    isDomainValid,
    isUserSyncValid,
    isBucketsAllValid
};
