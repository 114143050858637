import { CSSLint } from 'csslint';
import regex from '@util/regex';
import store from '../../store';

/**
 * Checks if the provided JavaScript code is valid and free from JSHint errors.
 *
 * @param {string} code - The JavaScript code to validate.
 * @returns {boolean} Returns true if the code is valid, false if JSHint errors are found.
 */
export function isValidJavascript(code) {
    if (!code) {
        return true;
    }

    code = `(function() { ${code} })`;

    try {
        new Function(code);
        return true;
    } catch (error) {
        return false;
    }
}

/**
 * Checks if the provided CSS code is valid and free from CSSLint errors.
 *
 * @param {string} code - The CSS code to validate.
 * @returns {boolean} Returns true if the code is valid, false if CSSLint errors are found.
 */
export function isValidCss(code) {
    if (!code) {
        return true;
    }

    const CSSLintResult = CSSLint.verify(code);
    const errorMessages = CSSLintResult.messages;

    // Check if there are no CSSLint errors with the type 'error'.
    return errorMessages.every(message => message.type !== 'error');
}

/**
 * Checks if the provided CSS selector is a valid selector that can be used with `querySelector`.
 *
 * @param {string} selector - The CSS selector to validate.
 * @returns {boolean} Returns true if the selector is valid, false if it's not.
 */
export function isValidCssSelector(selector) {
    try {
        document.createDocumentFragment().querySelector(selector);
    } catch (error) {
        return false;
    }
    return true;
}

/**
 * Checks if a domain is valid.
 *
 * @param {string} productionDomain - The domain to validate.
 * @returns {boolean} Returns true if the domain is valid or empty, false otherwise.
 */
export function isDomainValid(productionDomain) {
    return !productionDomain || regex.domain.test(productionDomain) || regex.url.test(productionDomain);
}

/**
 * Checks if the Aditude override configuration is valid.
 *
 * @param {object} aditudeOverrideConfig - The configuration to validate.
 * @returns {boolean} Returns true if the Aditude override configuration is valid or not enabled, false otherwise.
 */
export function isAditudeOverrideValid(aditudeOverrideConfig) {
    const { enabled, publisherKey, wrapperName } = aditudeOverrideConfig;

    if (!enabled) {
        return true;
    }

    const isPublisherKeyValid = typeof publisherKey === 'string' && publisherKey !== '' && regex.single_value.test(publisherKey);
    const isWrapperNameValid = typeof wrapperName === 'string' && wrapperName !== '' && regex.single_value.test(wrapperName);

    return isPublisherKeyValid && isWrapperNameValid;
}

/**
 * Checks if HTML code is valid.
 *
 * @param {string} code - The HTML code to validate.
 * @returns {boolean} Returns true if the HTML code is valid or if it's empty, false otherwise.
 */
export function isValidHtml(code) {
    return !code || store.getters['sites/htmlErrors'].length === 0;
}

/**
 * Retrieve a deeply nested value from an object using a dot-separated path.
 *
 * @param {object} obj - The object to traverse.
 * @param {string} path - The dot-separated path to the desired property.
 * @returns {*} The value at the specified path or undefined if not found.
 */
function deepGetValue(obj, path) {
    // Implementation remains the same
    for (const key of path.split('.')) {
        obj = obj[key];
        if (obj == null) {
            break;
        }
    }
    return obj;
}

/**
 * Checks if a value is falsy or empty.
 * Empty values: null, undefined, and empty strings and arrays.
 *
 * @param {*} v - The value to check.
 * @returns {boolean} Returns true if the value is not empty, false otherwise.
 */
function valueNotEmpty(v) {
    return v != null && v !== '' && (!Array.isArray(v) || v.length);
}

/**
 *
 * @param {Object} config - The main configuration object.
 * @param {Array} required - An array of dot-separated paths.
 * @returns {boolean} Returns true if all required options are present, false otherwise.
 */
export function checkSetupOptionRequired(config, required) {
    return required.every((path) => valueNotEmpty(deepGetValue(config, path)));
}
