import store from '@store/index';
import Role from '@enums/role';

const setupOptions = {
    adsAndSites: [
        { key: 'hasConsentManagerVersion2', text: 'Consent Management' },
        { key: 'hasDefaultLazyPixels', text: 'Default Lazy Loading Offset' },
        { key: 'hasCustomJs', text: 'Custom JS' },
        { key: 'hasLayouts', text: 'Ad Configuration' },
        { key: 'hasCss', text: 'Custom CSS' },
        { key: 'hasPassbacks', text: 'Passbacks' },
        {
            key: 'hasPrivacyFlags',
            text: 'Privacy Flags',
            warningTabKey: 'privacy-flags'
        },
        { key: 'isTestMode', text: 'AB Testing', warningTabKey: 'ab-testing' },
        {
            key: 'hasAuthorizedDomains',
            text: 'Authorized Domains',
            warningTabKey: 'authorized-domains',
        },
        {
            key: 'hasDevSiteURLOverride',
            text: 'Dev Site URL Override',
            warningTabKey: 'dev-site-url-override',
        },
        {
            key: 'hasAdLoadingOverride',
            text: 'Ad Loading Override (Delay Ads)',
            warningTabKey: 'ad-loading-override',
        },
        { key: 'imperativeApi', text: 'Imperative API' },
    ],
    headerBidding: [
        {
            key: 'hasAmazon',
            text: 'Amazon',
            required: ['amazon.pubId'],
            warningTabKey: 'amazon-configuration',
        },
        {
            key: 'hasAmazonSchain',
            text: 'Amazon Supply Chain',
            warningTabKey: 's-chain',
        },
        {
            key: 'hasPrebidCurrency',
            text: 'Prebid Currency',
            warningTabKey: 'prebid-configuration',
        },
        {
            key: 'hasPrebidFloors',
            text: 'Prebid Floors',
            warningTabKey: 'prebid-configuration',
        },
        {
            key: 'hasPrebidFirstLook',
            text: 'Prebid First Look',
            warningTabKey: 'prebid-configuration',
        },
        {
            key: 'hasTrafficShaping',
            text: 'Traffic Shaping',
            warningTabKey: 'traffic-shaping',
        },
        { key: 'hasPrebid', text: 'Prebid', warningTabKey: 'prebid-configuration' },
        {
            key: 'hasPrebidSchain',
            text: 'Prebid Supply Chain',
            warningTabKey: 's-chain',
        },
        {
            key: 'hasPrebidServer',
            text: 'Prebid Server Web',
            required: [
                'prebidServer.endpoint',
                'prebidServer.syncEndpoint',
            ],
            warningTabKey: 'prebid-server',
        },
        {
            key: 'hasNativeStyles',
            text: 'Prebid Native (Native Style Templates)',
            warningTabKey: 'native-styles',
        },
        {
            key: 'hasPrebidVideo',
            text: 'Prebid Video',
            warningTabKey: 'prebid-video',
        },
        {
            key: 'hasTopicsFpd',
            text: 'Topics FPD',
            warningTabKey: 'prebid-configuration',
        },
        {
            key: 'hasBidderThrottling',
            text: 'Throttle Non-Bids',
            required: ['bidderThrottling.failsToPause', 'bidderThrottling.unpauseSeconds'],
            warningTabKey: 'bidder-throttling',
        }
    ],
    analytics: [],
    identity: [
        // { key: 'hasHaloId', text: 'Halo ID', warningTabKey: 'halo' },
    ],
    monitoringAndBlocking: [],
};

function addSystemAdminOptions() {
    if (store?.getters?.userInformation?.role !== Role.SYSTEM_ADMIN) {
        return;
    }

    const systemAdminOptions = [
        { key: 'hasAdminCustomJs', text: 'Admin Custom JS' }
    ];

    systemAdminOptions.forEach(option => {
        if (setupOptions?.adsAndSites?.some(item => item.key === option.key)) {
            return;
        }

        const index = setupOptions?.adsAndSites?.findIndex(item => item.key > option.key);

        if (index === -1) {
            setupOptions?.adsAndSites?.push(option);  // If it's greater than all, add at the end
        } else {
            setupOptions?.adsAndSites?.splice(index, 0, option);  // Insert at the correct position
        }
    });
}

/**
 * Adds a setup option to a specified section in the setupOptions object.
 *
 * @param {Object} options - An object containing the setup option details.
 * @param {string} options.section - The section in which to add the setup option.
 * @param {string} options.key - The key for the setup option.
 * @param {string} options.text - The text or description for the setup option.
 * @param {string} [options.warningTabKey] - (Optional) The key for a warning tab associated with the option.
 * @param {boolean} [options.required] - (Optional) A boolean flag indicating if the option is required.
 *
 * @returns {void}
 */
export function addSetupOption({section, key, text, warningTabKey, required, disabled}) {
    addSystemAdminOptions();

    const option = {key, text, disabled};
    if (warningTabKey) {
        option.warningTabKey = warningTabKey;
    }
    if (required) {
        option.required = required;
    }
    if (!(section in setupOptions)) {
        setupOptions[section] = [];
    }
    setupOptions[section].push(option);
}

export default setupOptions;
