import Vue from 'vue';
import VueAxios from 'vue-axios';
import router from '@router/router';
import axios from 'axios';
import ToggleButton from 'vue-js-toggle-button';
import VToolTip from 'v-tooltip';
import App from './App.vue';
import store from './store/index';
import VueMask from 'v-mask';
import { Auth0Plugin } from './auth';

/* ----- VeeValidate ----- */
import '@validation';
/* ---------------------- */

const auth0Config = {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
        scope: 'openid profile email offline_access',
        audience: process.env.VUE_APP_AUTH0_AUDIENCE
    }
}

const { domain, clientId, authorizationParams } = auth0Config;

Vue.use(Auth0Plugin, {
    domain,
    clientId,
    authorizationParams,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    },
});

Vue.use(VueAxios, axios);
Vue.use(ToggleButton);
Vue.use(VToolTip);
Vue.use(VueMask);

import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

Vue.config.productionTip = false;

// global base url
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || '';

if (process.env.NODE_ENV !== 'test') {
    window.onload = () => {
        window.app = new Vue({
            router,
            store,
            render: h => h(App),
        }).$mount('#app');
    };
}

export default router;
